<script setup lang="ts">
import type { UtilsBulletPointProps } from './UtilsBulletPoint.props'

const { content, expandedLabel, reducedLabel, bulletPoint } =
  defineProps<UtilsBulletPointProps>()

const isExpanded = ref(false)

const showCta = computed(() => list.value.length > bulletPoint)

const parseMarkdown = (description: string) => description.split('<br>')

const list = computed(() =>
  content.map(description => parseMarkdown(description)).flat(1)
)

const bulletList = computed(() =>
  isExpanded.value ? list.value : list.value.slice(0, 2)
)

const toggleCta = () => {
  isExpanded.value = !isExpanded.value
}
</script>

<template>
  <div class="gap-xs flex flex-col items-start">
    <div class="text-light-6" :class="{ 'line-clamp-3': !isExpanded }">
      <ul
        v-for="(text, index) in bulletList"
        :key="`utils-info-text-${index}`"
        class="list-disc pl-4"
      >
        <li>
          {{ text }}
        </li>
      </ul>
    </div>

    <AtomsCta v-if="showCta" anatomy="link" @click="toggleCta">
      <template #label>
        {{ isExpanded ? reducedLabel : expandedLabel }}
      </template>
    </AtomsCta>
  </div>
</template>
