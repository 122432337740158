<script setup lang="ts">
import type { UIQuickBuyPopUpSlideProps } from './UIQuickBuyPopUpSlide.props'
import type { ColorOption } from '@design-system/components/Pdp/PdpColorSelector.props'

import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import type {
  Category,
  SizeDetails,
} from '@design-system/components/Pdp/PdpFitFinder.props'

const props = defineProps<UIQuickBuyPopUpSlideProps>()
const emit = defineEmits<{
  saveChanges: []
}>()

const { ts } = useI18n()
const { getCanonicalUrl } = useRouteHelper()
const localePath = useLocalePath()
const { addToCart, addToCartPayload, isLoading } = useAddToCart()
const { closeDialog, openDialog } = useDialog()

const { emitSizebayProductViewEvent } = useSizebayEvents()

const { removeFromCart } = useCart()
const { dispatchAddToCartEvent, dispatchRemoveFromCartEvent } =
  useGACartEvents()
const { wishlist, guestWishlist, isPdp } = useRouteHelper()
const route = useRoute()
const colorSelectorSidebarId = computed(
  () => `${SIDEBAR_IDS.pdpColorSelectorUiQuickBuy}-${props.product.id}`
)

const { isDialogOpen: isColorSelectorOpen } = useDialog(
  colorSelectorSidebarId.value
)

const { isDialogOpen: isFitFinderSidebarOpen } = useDialog(
  SIDEBAR_IDS.plpFitFinder
)

const { itemOptionColor } = await useWishlistQuickBuy()

const openFitFinderDialog = () => {
  openDialog(SIDEBAR_IDS.plpFitFinder)
  emitSizebayProductViewEvent(props.product.id, props.product.info.name)
}

const selectedOption = ref<ColorOption | null>(null)

const handleSelectedOptionChange = (option: ColorOption) => {
  itemOptionColor.value = option
  selectedOption.value = option
}

const pageView = computed(() =>
  route.fullPath.includes(wishlist) || route.fullPath.includes(guestWishlist)
    ? 'wishlist_page'
    : isPdp
      ? 'product_listing_page'
      : 'quick_product_popup'
)

const _dispatchAddToCartEvent = () =>
  dispatchAddToCartEvent({
    view: pageView.value,
  })

const addItemToCart = async () => {
  await addToCart()
  await _dispatchAddToCartEvent()
}

const removeItemFromCart = async () => {
  await removeFromCart(props.product.id)
  await dispatchRemoveFromCartEvent({
    view: 'cart_page',
    qty: props.product.quantity,
  })
}

const displayedColor = computed(() => {
  return selectedOption.value?.color
    ? formatProductName(selectedOption.value.color)
    : formatProductName(props.product.info.color ?? '')
})
const displayedSKU = computed(
  () =>
    selectedOption.value?.sku ?? getSkuWithoutSize(props.product.skuCode) ?? ''
)

const {
  title,
  altColorList,
  sizeList,
  sizes: skuSizes,
  sizesStocks,
  productDetail,
  sizeData,
  sizeGridCode,
} = await useQuickBuy(displayedSKU)

const { sizeGridData, productCategory, countries } = await useFitFinder(
  sizeGridCode.value ?? ''
)

const firstAltColorList = altColorList.value
const { sizes, selectedSizeIndex, selectedSize, selectedCountry } =
  useSizeSelector(sizeList, false, sizeData.value)

const isNotSelectedSize = ref(!!selectedSize.value)
//initialize my color
selectedOption.value =
  firstAltColorList.find(({ color }) => color === props.product.info.color) ||
  null

const info = computed(() => ({
  ...props.product.info,
  size: selectedSize.value
    ? `${selectedSize.value.size} (${selectedCountry.value})`
    : ts('pdp.sizeSelector.selectSize'),
  color: displayedColor.value,
  price: props.product.info.price,
}))

const selectedSkuCode = computed(() => selectedSize.value?.sku ?? null)
const sizeSection = ref<HTMLInputElement>()

const isOneExtSize = computed(() => skuSizes.value?.length === 1)

const saveChanges = async () => {
  if (!selectedSize.value) {
    isNotSelectedSize.value = true
    sizeSection.value?.scrollIntoView({ behavior: 'smooth' })
    return false
  }
  addToCartPayload.value = {
    name: title.value,
    sku_code: selectedSkuCode.value ?? '',
    metadata: {
      size: `${selectedSize.value?.size ?? ''} (${selectedCountry.value})`,
      color: selectedOption.value?.color,
      website: props.product.metadata,
    },
    quantity: props.product.quantity ?? 1,
  }
  if (props.isItemReplaced) await removeItemFromCart()
  await addItemToCart()
  emit('saveChanges')
  closeDialog(props.id)
}

const seeMoreDetails = () => {
  const path = getCanonicalUrl(selectedOption.value?.path ?? '')
  navigateTo(path)
}
</script>

<template>
  <OrganismsSidebarSlide
    :id="id"
    :header-props="{
      titleText: $ts('cartPage.quickBuy.title'),
      hasBackButton,
    }"
    :footer-props="{
      primaryText: props.isQuickBuyPlp
        ? $ts('cartPage.quickBuy.addToCart')
        : $ts('cartPage.quickBuy.saveChanges'),
      secondaryText: $ts('cartPage.quickBuy.moreDetails'),
      isBackgroundOffWhite: true,
      isPrimaryLoading: isLoading,
    }"
    :force-open="isColorSelectorOpen || isFitFinderSidebarOpen"
    :disable-click-to-close-outside="isFitFinderSidebarOpen"
    :is-alpha-layer-transparent="isNested"
    @primary-click="saveChanges"
    @secondary-click="seeMoreDetails"
  >
    <template #body>
      <div class="gap-lg md:gap-xl flex flex-col">
        <UIProductCardHorizontal
          v-bind="product"
          :path="localePath(product?.path) ?? ''"
          :image="selectedOption?.thumbnailUrl ?? ''"
          :info="info"
          :stock-qty="
            sizesStocks.find(sizeStock => sizeStock.sku === selectedSkuCode)
              ?.stock
          "
          :is-one-ext-size="isOneExtSize"
          :enable-quick-buy="false"
          is-price-visible
          has-image-border
        />
        <PdpEcommerceBlockColorSelector
          v-model:selected-color="displayedColor"
          :sidebar-id="colorSelectorSidebarId"
          :sku-code="product.id"
          :options="firstAltColorList"
          :has-back-button="true"
          @update:selected-option="
            (e: ColorOption) => handleSelectedOptionChange(e)
          "
        />
        <UtilsBulletPoint
          :bullet-point="2"
          :content="productDetail"
          :expanded-label="ts('cartPage.quickBuy.showMore')"
          :reduced-label="ts('cartPage.quickBuy.showLess')"
        />
        <div v-show="!isOneExtSize">
          <div class="gap-xs mb-5 flex justify-between">
            <p ref="sizeSection" class="text-book-5 uppercase">
              {{ $ts('pdp.sizeSelector.selectSize') }}
            </p>
            <!-- REMOVED FOR LIVE -->
            <AtomsCta anatomy="link" @click="openFitFinderDialog">
              <template #label> {{ $ts('pdp.fitFinder.label') }} </template>
            </AtomsCta>
          </div>
          <p
            v-if="isNotSelectedSize"
            class="text-book-6 text-primitives-red"
            role="alert"
            aria-live="assertive"
          >
            {{ $ts('pdp.sizeSelector.selectSizeError') }}
          </p>
          <PdpSizeSelector
            v-model:selected-size-index="selectedSizeIndex"
            v-model:selected-country="selectedCountry"
            :sizes="sizes"
            :sku-sizes="skuSizes"
            :hide-shortcut-elements="isFitFinderSidebarOpen"
          />
        </div>
      </div>
    </template>
  </OrganismsSidebarSlide>
  <OrganismsSidebarSlide
    :id="SIDEBAR_IDS.plpFitFinder"
    :header-props="{
      titleText: $ts('pdp.fitFinder.label'),
      hasBackButton: true,
      hasCloseButton: false,
    }"
    disable-click-to-close-outside
  >
    <template #body>
      <PdpFitFinder
        :category="productCategory as unknown as Category"
        :countries="countries"
        :sizes-details="sizeGridData as unknown as SizeDetails[]"
      />
    </template>
  </OrganismsSidebarSlide>
</template>
